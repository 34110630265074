import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'rnd-helmet';
import { injectIntl } from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import FormContext from "formContext";
import axios from "axios";
import useDateHelper from 'dateHelper';
import Loading from "@Components/Loading";
import JobCardList from "@Components/JobCardList";
import SubMenu from "@Components/SubMenu";
import GetRouteByName from 'getRouteByName';

function MyApplications({intl, staticContext, match}) {
  const { locale } = intl;
  const { additionalData, setAdditionalData, timesRendered } = useContext(FormContext);
  const { createNewDate, dateHasExpired } = useDateHelper();
  const { user, jobApplications, openApplications } = additionalData;
  const carousel = useRef();
  const jobsPageUrl = GetRouteByName('jobs').url;
  const [applicationStatuses, setApplicationStatuses] = useState(undefined);
  const monthsToExpire = 3;

  // Generate dataLayer for the page.
  useDataLayer(locale, staticContext);

  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      delete window.__ROUTE_DATA__;
    }
    else {
      Promise.all([
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user?currentLanguage=${locale}`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user/job-applications`),
        axios.get(`${process.env.REACT_APP_API_PREFIX}/user/open-applications`)
      ]).then(async ([user, jobApplications, openApplications]) => {
        let data = {
          user: user.data,
          jobApplications: jobApplications?.data,
          openApplications:  openApplications?.data,
        }

        setAdditionalData(prevState => ({
          ...prevState,
          ...data
        }));

      });
    }
  }, [timesRendered]);

  useEffect(() => {
    if (user && process.env.REACT_APP_TRACK_TRACE_REJECTED_ENABLED === "true") {
      axios.get(`${process.env.REACT_APP_API_PREFIX}/application-statuses-list?userId=${user.userId}`).then(response => {
        if (response.data && response.data.applications) {
          setApplicationStatuses(response.data.applications);
        }
        else {
          setApplicationStatuses([]);
        }
      }).catch(() => {
        setApplicationStatuses([]);
      });
    }
  }, []);

  useEffect(() => {
    if (!carousel.current) return ;
    const { Carousel } = require("@ffw/randstad-local-orbit/js/components/carousel");
    new Carousel(carousel.current);
  }, [user]);

  if (!user || !jobApplications || !openApplications ||
    (process.env.REACT_APP_TRACK_TRACE_REJECTED_ENABLED === "true" && !applicationStatuses)) {
    return <Loading/>;
  }

  /**
   * Function that filters applications data and return old & new separately.
   * New applications have applicationId. The filter works based on that.
   * @param {object} data - Job applications object.
   * @returns { Object } - oldApplications, newApplications
   */
  const splitApplications = (data) => {
    let oldApplications = {
      applications: [],
    }
    let newApplications = {
      applications: [],
    }

    if (data && Array.isArray(data.applications)) {
      data.applications.map((item) => {
        if (item.details && item.details.applicationId) {
          newApplications.applications.push(item);
        }
        else {
          oldApplications.applications.push(item);
        }
      })
    }

    return { oldApplications, newApplications };
  }

  const parseApplications = (applications, getExpiredItems = false, type = 'job') => {
    applications.map(item => {
      item.created_timestamp = (item.created_timestamp ? item.created_timestamp : item.timestamp) || item.created;
      const createdDate = createNewDate(item.created_timestamp);
      const expirationDate = new Date(createdDate.setMonth(createdDate.getMonth() + monthsToExpire));
      const expired = dateHasExpired(new Date(), expirationDate);

      item.applicationExpired = expired;
      item.applicationCardType = type;

      return expired;
    });

    return applications.filter(
      item => getExpiredItems ? item.applicationExpired : !item.applicationExpired
    );
  }

  // Get active job applications.
  const getActiveJobApplications = (jobApplicationsData) => (
    !jobApplicationsData || jobApplicationsData.applications.length < 1 ? [] : parseApplications(jobApplicationsData.applications)
  );

  // Get active open (initiative) applications.
  const getActiveOpenApplications = (openApplicationsData) => (
    !openApplicationsData || openApplicationsData.applications.length < 1 ? [] :
      parseApplications(openApplicationsData.applications, false, 'open')
  );

  // Get past job and open applications.
  const getPastApplications = (jobApplicationsData, openApplicationsData) => {
    const open = openApplicationsData && openApplicationsData.applications && openApplicationsData.applications.length > 0 ?
      parseApplications(openApplicationsData.applications, true, 'open') : [];
    const job = jobApplicationsData && jobApplicationsData.applications && jobApplicationsData.applications.length > 0 ?
      parseApplications(jobApplicationsData.applications, true) : [];
    if (process.env.REACT_APP_TRACK_TRACE_REJECTED_ENABLED === 'true') {
      /* The following code is only valid for PT. In the PoC it is used by flag 'checkRejectedStatus' */
      const allApplications = [...jobApplicationsData.applications];
      let atsRejectedApplications = [];

      allApplications.forEach((applicationJob) => {
        const applicationId = applicationJob.details && applicationJob.details.applicationId;

        if (applicationId) {
          let rejected = false;

          // Get rejected value (true/false).
          applicationStatuses.find((atsApplication) => {
            if (atsApplication.applicationId === applicationId) {
              rejected = atsApplication.rejected;
            }
          });

          if (rejected && rejected === 'true') {
            atsRejectedApplications.push(applicationJob);
          }
        }
      })

      const pastAndRejectedApplications = [
        ...new Map(
          [...job, ...atsRejectedApplications].map((v) => [
            v.jobId,
            v,
          ])
        ).values(),
      ];

      return pastAndRejectedApplications;
    }

    return job.concat(open);
  }

  /* SPLIT APPLICATIONS DATA INTO TWO PILES - OLD & NEW (new = T&T api). */
  const splitJobApplications = splitApplications(jobApplications);
  const splitOpenApplications = splitApplications(openApplications);

  /* OLD APPLICATIONS. */
  const activeJobApplications = getActiveJobApplications(splitJobApplications.oldApplications);
  const openJobApplications = getActiveOpenApplications(splitOpenApplications.oldApplications);

  /* NEW APPLICATIONS. */
  const openNewApplications = getActiveJobApplications(splitJobApplications.newApplications);

  /* Combine applications. */
  const pastJobApplications = getPastApplications(jobApplications, openApplications);
  const openActiveJobApplications = [...activeJobApplications, ...openJobApplications, ...openNewApplications];
  let activeApplications = openActiveJobApplications;

  if (process.env.REACT_APP_TRACK_TRACE_REJECTED_ENABLED === 'true') {
    const jobApplicationsToDeleteSet = new Set(pastJobApplications);
    // We do this on PT, because we move to Past applications all rejected applications from T&T
    // so we have to remove them from the active/open applications array.
    activeApplications = openActiveJobApplications.filter((name) => {
      return !jobApplicationsToDeleteSet.has(name);
    });
  }

  let activeJobAplicationsCount = activeApplications
    ? activeApplications.length
    : 0;

  const pastJobApplicationsCount = pastJobApplications
    ? pastJobApplications.length
    : 0;

  const renderJobs = (applications) => {
    // Sort by created_timestamp.
    if (applications) {
      applications.sort(function (a, b) {
        return new Date(b.created_timestamp) - new Date(a.created_timestamp);
      });
    }

    return <JobCardList
        showMoreVisibility={true}
        offsetPerPage={15}
        jobs={applications}
    />
  }

  let staticArticles = [];

  if (process.env.REACT_APP_MY_APPLICATIONS_ARTICLES === 'true') {
    staticArticles = [
      {
        image: '/myrandstad-app/assets/image/my-applications/fr/candidater-article.webp',
        title: 'Comment sortir du lot ?',
        link: '/candidater/comment-sortir-lot/',
      },
      {
        image: '/myrandstad-app/assets/image/my-applications/fr/entretien-article.webp',
        title: 'Salaire brut, net, RTT, avantages : il n’y a pas de questions taboues !',
        link: '/entretien/salaire-brut-net-rtt-avantages-il-ny-pas-questions-taboues/',
      },
      {
        image: '/myrandstad-app/assets/image/my-applications/fr/carriere-article.webp',
        title: 'Démission ou rupture conventionnelle : tout savoir pour partir dans les meilleures conditions.',
        link: '/carriere/demission-rupture-conventionnelle-tout-savoir-partir-meilleures-conditions/',
      },
      {
        image: '/myrandstad-app/assets/image/my-applications/fr/cv-article.webp',
        title: 'Réussir son CV, exemple à l’appui ! — N°1',
        link: '/cv/reussir-son-cv-exemple-lappui-ndeg1/',
      },
    ];
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: "Menu.MyApplications" })} |{" "}
          {intl.formatMessage({ id: "Breadcrumb.MyApplications" })}
        </title>
        <header className="header bg-variant-white header--s header--welcome" />
      </Helmet>
      <div className="block--s">
        <SubMenu />
        <div className="block__wrapper block__wrapper--stacked wrapper">
          <div className="my-environment-container" key="type-open">
            <h2 className="title--m hidden--until-l">
              {intl.formatMessage({ id: "Applications.Tabs.Active" }, { u: activeJobAplicationsCount })}
            </h2>
            <h2 className="block__title hidden--from-l">
              {intl.formatMessage({ id: "Applications.Tabs.Active" }, { u: activeJobAplicationsCount })}
            </h2>
            {activeApplications  && activeApplications .length > 0 ? (
              <div
                className={`block__content block__content--full-on-s block__content--full-on-m pt-m`}
              >
                {renderJobs(activeApplications)}
              </div>
            ) :
            (
              <>
                <p className="content-block__description">
                  {intl.formatMessage({
                    id: "Applications.NoApplications.Text",
                  })}
                </p>

                <a href={jobsPageUrl} className="button button--m mt-s">
                  {intl.formatMessage({ id: "Utils.JobSearch" })}
                </a>
              </>
            )}
            {pastJobApplications && pastJobApplications.length > 0 && (
              <>
                <h2 className="title--m hidden--until-l pt-xl">
                {intl.formatMessage({ id: "Applications.Tabs.Past" }, { u: pastJobApplicationsCount })}
                </h2>
                <h2 className="block__title hidden--from-l pt-s">
                  {intl.formatMessage({ id: "Applications.Tabs.Past" }, { u: pastJobApplicationsCount })}
                </h2>
                <div
                  className={`block__content block__content--full-on-s block__content--full-on-m pt-m`}
                >
                  {renderJobs(pastJobApplications)}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {process.env.REACT_APP_MY_APPLICATIONS_ARTICLES === "true" && (
        <div className="block blog-overview blog-overview--carousel blog-overview--carousel-on-s bg-variant-brand-tertiary">
          <div className="block__wrapper block__wrapper--stacked">
            <div className="block__header block__header--l block__header--split wrapper">
              <h2 className="block__title">
                {intl.formatMessage({id: 'MyApplications.ArticlesSection.Title'})}
              </h2>

              <a href="/conseils-carriere/" className="block__more-link">
                {intl.formatMessage({id: 'MyApplications.ArticlesSection.LinkText'})}
              </a>
            </div>
            <div className="block__content">
              <div className="blog-overview__wrapper wrapper" data-rs-carousel-wrapper="">
                <ul className="blog-overview__list" ref={carousel} data-rs-carousel="blog-overview--carousel" aria-roledescription="carousel">
                  {staticArticles.map((article, index) => {
                    return (
                      <li key={index} className="blog-overview__item">
                        <a className="blog-overview__link" href={article.link}>
                          <article className="blog-overview__article">
                        <span className="blog-overview__media aspect-ratio aspect-ratio--3-2 media-block--center">
                          <img src={article.image} alt={article.title} data-rs-carousel-image=""/>
                        </span>
                            <div className="blog-overview__content">
                              <span className="blog-overview__title">{ article.title }</span>
                            </div>
                          </article>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(MyApplications);
